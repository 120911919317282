.box {
  
  transform: translate(-50%, -50%);
  padding: 5px 40px;
  display: inline-block;
  text-decoration: none;
  overflow: hidden;
  cursor: pointer;
  background-color: #22577A;
  transition:
    box-shadow 0.4s ease,
    background-color 0.4s ease,
    color 0.4s ease;
  box-shadow:
    0 0 2px 0 rgba(34, 87, 122, 0.1),
    0 0 4px 0 rgba(34, 87, 122, 0.2),
    0 0 6px 0 rgba(34, 87, 122, 0.3),
    0 0 8px 0 rgba(34, 87, 122, 0.4),
    0 0 12px 0 rgba(34, 87, 122, 0.5),
    0 0 18px 0 rgba(34, 87, 122, 0.6);
    border-radius: 2rem;
}

.box:hover {
  background-color: #57CC99;
  box-shadow:
    0 0 2px 0 rgba(87, 204, 153, 0.1),
    0 0 4px 0 rgba(87, 204, 153, 0.2),
    0 0 6px 0 rgba(87, 204, 153, 0.3),
    0 0 8px 0 rgba(87, 204, 153, 0.4),
    0 0 12px 0 rgba(87, 204, 153, 0.5),
    0 0 18px 0 rgba(87, 204, 153, 0.6),
    0 0 4px 0 rgba(87, 204, 153, 0.7);
}

.box span {
  color: #fff;
  letter-spacing: 2px;
  background-color: transparent;
  border-color: transparent;
}

.box i {
  position: absolute;
  z-index: -1;
  left: 0;
  top: 70%;
  transform: translateY(-50%);
  width: 100%;
  height: 150px;
  background-color: inherit;
  box-shadow: inset 0 0 50px rgba(0, 0, 0, 0.5);
  transition:
    transform 0.4s linear,
    top 1s linear;
  overflow: hidden;
}

.box i:before,
.box i:after {
  content: "";
  position: absolute;
  width: 200%;
  height: 200%;
  top: 0;
  left: 50%;
  transform: translate(-50%, -75%);
}

.box i:before {
  border-radius: 46%;
  background-color: #090a0f;
  animation: animate 5s linear infinite;
}

.box i:after {
  border-radius: 40%;
  background-color: rgba(20, 20, 20, 0.5);
  animation: animate 10s linear infinite;
}

@keyframes animate {
  0% {
    transform:
      translate(-50%, -75%) rotate(0deg);
  }

  100% {
    transform:
      translate(-50%, -75%) rotate(360deg);
  }
}