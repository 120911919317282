body {
  
  font-family: "League Spartan";

  height: 100vh;
  color: white;
  align-content: center;
  display: flex;
  justify-content: center;
  min-height: 100% !important;
  width: 100vw;
  min-width: 100%;
  
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


/* Logo Section */
.logo {
  display: flex;
  border-radius: 14px;
  max-width: 100px;
  max-height: 100px;
  text-decoration: none;
}
.header{
  display:flex;
  border-radius: 14px;
  max-height: 150px ;
  align-items: center;
  width: fit-content;
  text-decoration: none;

}
 a {
  color: #fff;
  text-decoration: none;
 }
 a:hover{
  text-decoration: none;
  color: #57CC99;
  transition: all 0.8s;
 }

 /* Navigation Bar Section */
.nav {
  position: relative;
  text-decoration: none;
  font-size: 1.2rem;
  font-weight: 400;
  letter-spacing: 0.5px;
  padding: 0 10px;
  color: white;
  float: left;
  box-sizing: border-box;
  border-radius: 1rem;
  }


.nav-padding {
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 10px;
}
.nav.active {
  color: #57CC99
}
  
.nav:after {
  background: none repeat scroll 0 0 transparent;
  bottom: 0;
  content: "";
  display: block;
  height: 2px;
  left: 50%;
  position: absolute;
  background: #57CC99;
  transition: width 0.3s ease 0s, left 0.3s ease 0s;
  width: 0;
  border-radius: 1rem;
}

.nav:hover:after,
.nav.active:after {
  width:100%;
  left: 0;
  background: #4db488;
  border-radius: 1rem;
  
}
.navbar .navbar-nav .nav-link:hover {
  background-color: none;
  color: #fff;
  border-radius: 1rem;
}
/* Page Header Section in About*/
h1{
  background-image: linear-gradient(90deg, #0be4dd, rgb(98, 243, 1));
  background-clip: text;
  color: transparent;
  font-weight: 900;
}
h5{
  padding-top: 10px;
}
.pgheader{
  display: flex;
  padding: 0px 20px 48px 20px !important;
  justify-content: left;
  font-weight: 800;
}

.colSpacing{
  max-width: 1080px;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
  
  
} 
.pg{
  display: flex;
  padding: 10px 20px 20px 20px
}
.pg2{
  display: block;
  align-items: center;
  justify-content: center;
  padding:  10px 20px 20px 20px;
  max-width: 250px !important;
}
.pg3{
  display: block;
    align-items: center;
    justify-content: center;
    padding: 10px 20px 20px 20px;
}
/* Added alignment elements for use */
.alignCenter{
  display: flex;
  align-items: center;
  justify-content: center;
  justify-items: center;
}
.alignRight{
  display: flex;
  align-items:flex-end;
  justify-content: flex-end;
  justify-items: flex-end;
}
.alignLeft{
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  justify-items: flex-start;
}


.btnAlignRight {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  justify-items: flex-end;
  padding-right: 10px;
}
.btnContact{
  display: flex;
  max-width: 200px !important;
  min-width: 199px;
  align-items: flex-end;
  justify-content: flex-end;
  justify-items: flex-end;
  margin-left: 100px;
  
  
}
.btnContacttwo{
  border-color: transparent !important;
}

/* Body Borders and Fonting elements */
.border{
  border-radius: 1rem;
  border-width: 3px;
  margin-left: 5px;
  margin-right: 5px;
  margin-bottom: 40px;
  border-color: #57CC99 !important;
  align-items: stretch;
  
}
.underline{
  text-decoration: underline;
}
.fontsize{
  font-size: large;
}

/* vertical timeline css edit */

.vCard{
  border-radius: 1rem;
  border-color: transparent;
  background-color: transparent;
  display: flex !important;
  max-width: 1080px;
  min-height: 100%;
}


.experience-icon {
  font-size: 300%;
  margin-top: 25%;
  text-align: center;
}
.main-badge {
  font-size: 13px !important;
  text-align: left !important;
  padding: 5px 8px 5px 8px !important;
  vertical-align: baseline;
  background-color: #3c4d5a !important;
  color: white;
  font-weight: lighter !important;
  font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
}

.experience-badge {
  font-size: 11px !important;
  text-align: left !important;
  padding: 5px 8px 5px 8px !important;
  vertical-align: baseline;
  background-color: #f9f5e9 !important;
  color: black;
  font-weight: lighter !important;
  font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
}

.vertical-timeline-element-date {
  padding: 0 !important;
  margin: 0 !important;
}
@media only screen and (max-width: 1080px) {
  .experience-icon {
    font-size: 17%;
    margin-top: 27%;
    text-align: center;
  }
}
/* Card CSS */
.pd {
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;
}

.mar {
  margin: 30px 30px 30px 30px;
}
.proPicimg{
  max-height: 310px;
}

/* Inline text edit */
.text{
  background-image: linear-gradient(90deg, #0be4dd, rgb(98, 243, 1));
  background-clip: text;
  color:transparent;
  font-weight: 700;
}


/* Card Css */
.cardMui{
  background-image: radial-gradient(ellipse at bottom, #1b2735 0%, #090a0f 100%);
  color: #fff !important;
  min-width: 275px !important;
  border-color: #57CC99 !important;
  
  
}
.linkButton{
  color: #fff !important;
  display: flex !important;
  bottom: 10px;
  align-items: flex-end !important;
}
.cardProject {
  border-radius: 0.75rem !important;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 10px;
  min-width: 320px !important;
  
}
.cardImg {
  border-radius: .5rem;
}
/* Contact From Css */
.contactText{
  display: flex;
  margin: 10px 10px 10px 10px;
  background: linear-gradient(45deg, #426c9c 0%,
    #090a0f 100%);
  opacity: 0.5;
  color: #fff;
  border-radius: .5rem;
 
  max-width:100%;
  min-height:50px;
  height:100%;
  width:100%;
  border-color:#57CC99 ;

}
.contactLabel{
  font-family: "League Spartan";
  font-weight: 400;
  display:flex;
  flex-direction: column;
}
.contactText:focus{
  opacity: 1;
  color: white;
}
.contactText:visited{
  opacity: 1;
  background: transparent;
}

/* Blog CSS */
.h2mar{
  margin-bottom: 50px;
}
.borderline{

  border-bottom: 1px solid #57CC99
}
.btntrans{
  background-color: transparent;
  border-color: transparent;
  color:white;
  width: 100%;
  max-width: 725px;
}
.datel{
  width: 80%;
  float: left;
}
.dater{
  width: 20%;
  float: right;
}
/* Skills added css section */
.skills {
  display: flex;
  padding: 0px 20px 48px 20px !important;
  justify-content: left;
  font-weight: 400;
}
.skillsTab{
    background-image: linear-gradient(90deg, #0be4dd, rgb(98, 243, 1));
      background-clip: text;
      color: transparent;
      font-weight: 700;;
}

.skillsTab.active{
  color: #57CC99;
  border-color: #57CC99;
  border-radius: 1rem;
font-weight: 500;
}


/* Footer Section */
.footer {
  position: static;
  padding: 10px 10px 10px 10px;
  bottom: 20px;
  width: 100%;
  max-width: 1080px;
  height: 40px;
  justify-content: center;
  align-items: baseline;

  
}
/* this is the skills icon section */
.icons{
margin: 5px;
}

.footericons{
  margin: 10px;

}

/* This is for badges within blog section */
.tags{
  display:inline-block;
  align-items: center;
justify-content: center;
 background-image: linear-gradient(90deg, #0be4dd, rgb(98, 243, 1));
 color:black;
 margin: 5px 2px 5px 2px;
}

.marginTop{
margin-top: 13%;
}