* {
  margin: 0;
  padding: 0;
}

.zindex {
  z-index: 7 !important;
}

@keyframes move-twink-back {
  from {
    background-position: 0 0;
  }

  to {
    background-position: -10000px 5000px;
  }
}

@keyframes move-clouds-back {
  from {
    background-position: 0 0;
  }

  to {
    background-position: 10000px 0;
  }
}

.stars,
.twinkling,
.clouds
{
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  display: block;

}

.stars {
  background: #000 url(../src/images/stars.png) repeat top center;
  z-index: 0;
 
}

.twinkling {
  background: transparent url(../src/images/twinkling.png) repeat top center;
  z-index: 1;
  animation: move-twink-back 200s linear infinite;
}
.clouds {
  background: transparent url(../src/images/clouds.png) repeat top center;
  z-index: 2;
  opacity: .4;
  animation: move-clouds-back 200s linear infinite;
}